import { useTranslation } from "react-i18next";
import { useMatchesData } from "~/utils/useMatchesData";
import { useMatches } from "react-router";
import LanguageSelectListItems from "~/components/LanguageSelectListItems";
import Flag from "react-flagpack";

type Props = {
  showLabel?: boolean;
};
export default function LanguageSelect({ showLabel }: Props) {
  const matches = useMatches();
  const data = useMatchesData("root");
  const { t, ready, i18n } = useTranslation("translation");
  return (
    <>
      <div className={`dropdown dropdown-bottom dropdown-end  z-20`}>
        <label
          tabIndex={0}
          className="py-4 m-0 w-full flex justify-center items-center font-normal no-underline text-base cursor-pointer"
        >
          <div className="avatar mr-3">
            <Flag
              code={
                i18n?.resolvedLanguage === "en"
                  ? "GBR"
                  : i18n?.resolvedLanguage?.toUpperCase()
              }
              size="l"
              hasBorder={false}
              hasBorderRadius={false}
              gradient="real-linear"
            />
          </div>
          {showLabel && (
            <div className=" mr-auto text-white flex gap-2">
              {data.lngs[i18n?.resolvedLanguage]?.nativeName}

              <svg
                className="fill-current "
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
              >
                <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
              </svg>
            </div>
          )}
        </label>
        <ul
          tabIndex={0}
          className="dropdown-content menu w p-2 shadow bg-base-100 rounded-box w-full  min-w-[14rem]"
        >
          <LanguageSelectListItems />
        </ul>
      </div>
    </>
  );
}
